<template>
  <div>
    <loading-screen v-if="isLoading"></loading-screen>
    <v-card v-if="!isLoading">
      <v-card-title>
        Edit Meta Data - {{ data.name }}
        <v-spacer></v-spacer>
        <v-btn
          small
          depressed
          color="warning"
          @click="onSaveMeta"
          :loading="loading"
        >
          Simpan
        </v-btn>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <quill-editor
          class="editor"
          ref="myTextEditor"
          :value="content"
          :options="editorOption"
          @change="onEditorChange"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
          v-if="
            contentKey != 'firebase_daily_limit' &&
            contentKey != 'max_phonenumber_verified_request' &&
            contentKey != 'ngegame_email' &&
            contentKey != 'ngegame_phone_number' &&
            contentKey != 'api_key'
          "
        >
        </quill-editor>
        <!-- <editor
          v-if="
            contentKey != 'firebase_daily_limit' &&
            contentKey != 'max_phonenumber_verified_request' &&
            contentKey != 'ngegame_email' &&
            contentKey != 'ngegame_phone_number' &&
            contentKey != 'api_key'
          "
          api-key="no-api-key"
          v-model="content"
          :init="{
            height: 500,
            menubar: 'file edit view insert format tools table help',
            plugins:
              'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
            toolbar:
              'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
          }"
        /> -->
        <v-text-field v-else v-model="content"></v-text-field>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import LoadingScreen from "../components/LoadingScreen.vue";
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import debounce from 'lodash/debounce'

export default {
  name: "MetaDetail",

  components: { LoadingScreen, quillEditor },

  data() {
    return {
      content: null,
      contentKey: null,
      data: [],
      isLoading: true,
      loading: false,
      valid: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  mounted() {
    this.checkIsLogin();
    this.getData();
  },

  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
  },

  methods: {
    onEditorChange: debounce(function (value) {
      this.content = value.html;
    }, 466),
    onEditorBlur(editor) {
      console.log("editor blur!", editor);
    },
    onEditorFocus(editor) {
      console.log("editor focus!", editor);
    },
    onEditorReady(editor) {
      console.log("editor ready!", editor);
    },
    checkIsLogin() {
      if (
        this.$store.getters.getToken == null ||
        this.$store.getters.getToken == ""
      ) {
        this.$router.push("/");
      }
    },

    getData() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      axios
        .create({ withCredentials: false })
        .get(
          `${process.env.VUE_APP_API_URL}/v1/admin/meta_data/${this.$route.params.metaKey}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then((response) => {
          this.isLoading = false;

          this.content = response.data.result.metaValue;
          this.contentKey = response.data.result.metaKey;
          this.data = response.data.result;

          // console.log(response);
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.isLoading = false;
          console.log(error);
        });
    },
    async onSaveMeta() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        const form = new FormData();
        form.append("data", this.content);

        await axios
          .create({ withCredentials: false })
          .post(
            `/admin/meta_data/${this.$route.params.metaKey}/update`,
            form,
            config
          )
          .then((response) => {
            this.loading = false;
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.warning(error.response.data.message);
          });
      }
    },
  },
};
</script>
