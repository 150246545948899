<template>
  <div id="loading">
    <div class="d-flex flex-column img">
      <img
        :src="require('../assets/img/ngegame-text-logo2.svg')"
        class="align-self-center"
        width="300px"
      />
      <img
        class="align-self-center"
        :src="require('../assets/img/Infinity.gif')"
        alt="loading..."
        width="200"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
};
</script>

<style>
#loading {
  position: absolute;
  z-index: 1000;
  background-color: white;
  height: 100vh;
  width: 100vw;
  text-align: center;
  line-height: 100vh;
}
.img {
  position: relative;
  margin: auto;
  margin-top: 20%;
}
</style>
